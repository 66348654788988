import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moneyback from '../../assets/moneyback.svg';
import freeDelivery from '../../assets/freeDelivery.svg';
import inspection from '../../assets/inspection.svg';
import returnright from '../../assets/return.svg';
import safedelivery from '../../assets/safedelivery.svg';
import customercare from '../../assets/customercare.svg';
import verified from '../../assets/verified.svg';
import chatbubble from '../../assets/chatbubble.svg';
import writing from '../../assets/writing.svg';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { colorOptions, conditionOptions } from '../../config/configListing';
import { richText } from '../../util/richText';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { GoShieldCheck } from 'react-icons/go';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  Modal,
  AvatarSmall,
  AvatarMedium,
  H1,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import SectionPublicDataMaybe from './SectionPublicDataMaybe.js';
import SectionFeaturesMaybe from './SectionFeaturesMaybe.js';
import { FaCheck } from 'react-icons/fa6';
import { marketplaceData } from '../../marketplace-data.js';
const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;
const popNouvelleUser = process.env.REACT_APP_POP_NOUVELLE_USER;
const maxPrice = 50000;
export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
  } = props;
  const locale = intl.locale;
  const [showBuyerFeesModal, setShowBuyerFeesModal] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [showCondition, setShowCondition] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const handleShowBuyerFeesModal = (state, showInfo = true) => {
    setShowBuyerFeesModal(state);
    setShowInfo(showInfo);
  };
  const [isOpenBusinessToCustomer, setIsOpenBusinessToCustomer] = useState(false);
  const handleShowBusinessToCustomer = state => {
    setIsOpenBusinessToCustomer(state);
  };

  const handleShowCondition = state => {
    setShowCondition(state);
  };
  const handleShowAuth = state => {
    setShowAuth(state);
  };
  const listingConfig = config.listing;

  const listingId = new UUID(rawParams.id);

  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const {
    department,
    category,
    subCategory,
    designer,
    size,
    condition,
    colour,
    otherBrand,
    refrenceNumber: refrenceId,
    gender,
  } = currentListing?.attributes?.publicData || {};
  const data = marketplaceData;
  const categoryData = data?.find(d => d.value === department);
  const genderData = categoryData?.gender;
  const subCategoryData = gender
    ? genderData?.enumOptions?.find(d => d.value === gender)?.subCategories?.enumOptions
    : categoryData?.subCategories?.enumOptions;
  const productData = subCategoryData?.find(d => d.value === category)?.products?.enumOptions;
  const productObject = subCategory ? productData?.find(d => d.value === subCategory) : null;
  const brandObject = designer
    ? productObject?.brands?.enumOptions?.find(d => d.value === designer)
    : null;
  const designerLabel = brandObject?.label;
  const ifOtherBrand = designerLabel === 'Other' ? otherBrand : designerLabel;
  const pricee = currentListing?.attributes?.price;
  const isPriceGreaterOrEqual = pricee?.amount >= maxPrice;

  const conditionLabel = condition
    ? conditionOptions?.find(i => i.value === condition)?.label
    : null;

  const colourObject = colour ? colorOptions?.find(i => i.value === colour) : null;
  const params = {
    departmentCategory: `${department}-${category}`,
    subCategory,
    designer,
    ...rawParams,
  };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;
  const topbar = <TopbarContainer />;

  // if (loading) {
  //   return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  // }

  // if (invalidRefrenceId) {
  //   return <NotFoundPage />;
  // }

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state === 'published';
  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );
  const { priceWithoutBuyerFee } = publicData || {};
  const buyerFee = priceWithoutBuyerFee ? price?.amount - priceWithoutBuyerFee : null;
  const formatPriceWithoutBuyerFee = priceWithoutBuyerFee
    ? priceData(new Money(priceWithoutBuyerFee, config.currency), config.currency, intl)
    : null;

  const formatBuyerFee = buyerFee
    ? priceData(new Money(buyerFee, config.currency), config.currency, intl)
    : null;
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const processType = isBooking ? 'booking' : 'purchase';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && !currentUser?.attributes?.stripeConnected;
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;
  const authorId = ensuredAuthor?.id?.uuid;
  const { created_by } = publicData || {};

  const isPopnouvelleUser = authorId === popNouvelleUser || created_by == 'script';
  const showAuthentication =
    isPopnouvelleUser || isPriceGreaterOrEqual ? (
      <div className={css.authentication}>
        <GoShieldCheck className={css.icon} />
        <p className={css.authLabel}>
          {intl.formatMessage({ id: 'OrderPanel.OrderPanel.authenticationIncluded' })}
        </p>
      </div>
    ) : null;
  const business2Customer = (
    <div className={css.checkContainer}>
      {conditionLabel ? (
        <div className={css.container}>
          <p className={css.text}>
            <span className={css.label}> {conditionLabel}</span>
            <span className={css.moreInfo} onClick={() => handleShowCondition(true)}>
              {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
            </span>
          </p>
        </div>
      ) : null}
      {colourObject ? (
        <div className={css.container}>
          <p className={css.text}>
            {/* <FormattedMessage id="ListingPage.colorTitle" />
              {': '} */}
            <span className={css.label}> {colourObject.label}</span>
          </p>
        </div>
      ) : null}
      {showAuthentication}
      <div className={css.mobileText}>
        <div className={css.avatarWrapper}>
          <AvatarMedium className={css.avatar} user={currentListing.author} />
        </div>
        <p className={css.auth}>
          <span>
            <div className={css.authContainer}>
              <FaCheck className={css.check} />
              <FormattedMessage
                id="OrderPanel.OrderPanel.authAndQualityControl"
                values={{ br: <br /> }}
              />
            </div>

            {locale === 'en-US' && (
              <span className={css.moreInfoA} onClick={() => handleShowAuth(true)}>
                {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
              </span>
            )}
          </span>
          {locale !== 'en-US' && (
            <span className={css.moreInfoA} onClick={() => handleShowAuth(true)}>
              {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
            </span>
          )}
        </p>
        <p className={css.auth}>
          <span>
            <FaCheck className={css.check} />
            <FormattedMessage id="OrderPanel.b2c.freeShipping" />
          </span>
        </p>
        <p className={css.auth}>
          <span>
            <FaCheck className={css.check} />
            <FormattedMessage id="OrderPanel.b2c.returnPolicy" />
          </span>
        </p>
        <p className={css.auth}>
          <span>
            <div className={css.authContainer}>
              <FaCheck className={css.check} />
              <FormattedMessage id="OrderPanel.b2c.continuousSupport" />
            </div>
          </span>
          <span className={css.moreInfoA} onClick={() => handleShowBusinessToCustomer(true, false)}>
            {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
          </span>
        </p>
      </div>
    </div>
  );
  const customer2Customer = (
    <div className={css.checkContainer}>
      {conditionLabel ? (
        <div className={css.container}>
          <p className={css.text}>
            <span className={css.label}> {conditionLabel}</span>
            <span className={css.moreInfo} onClick={() => handleShowCondition(true)}>
              {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
            </span>
          </p>
        </div>
      ) : null}
      {colourObject ? (
        <div className={css.container}>
          <p className={css.text}>
            {/* <FormattedMessage id="ListingPage.colorTitle" />
              {': '} */}
            <span className={css.label}> {colourObject.label}</span>
          </p>
        </div>
      ) : null}
      {showAuthentication}
      <div className={css.mobileText}>
        <div className={css.avatarWrapper}>
          <AvatarMedium className={css.avatar} user={currentListing.author} />
        </div>
        <p className={css.auth}>
          <span>
            <div className={css.authContainer}>
              <FaCheck className={css.check} />
              {isPriceGreaterOrEqual ? (
                <FormattedMessage id="OrderPanel.c2c.authenticity" values={{ br: <br /> }} />
              ) : (
                <FormattedMessage
                  id="OrderPanel.c2c.optionalAuthenticity"
                  values={{ br: <br /> }}
                />
              )}
            </div>
            {locale === 'en-US' && (
              <span className={css.moreInfoA} onClick={() => handleShowAuth(true)}>
                {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
              </span>
            )}
          </span>
          {locale !== 'en-US' && (
            <span className={css.moreInfoA} onClick={() => handleShowAuth(true)}>
              {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
            </span>
          )}
        </p>
        <p className={css.auth}>
          <span>
            <FaCheck className={css.check} />
            <FormattedMessage id="OrderPanel.c2c.moneyBackGuarantee" />
          </span>
        </p>
        {!isPriceGreaterOrEqual && (
          <p className={css.auth}>
            <span>
              <FaCheck className={css.check} />
              <FormattedMessage id="OrderPanel.c2c.returnWithin72Hours" />
            </span>
          </p>
        )}
        <p className={css.auth}>
          <span>
            <FaCheck className={css.check} />
            <FormattedMessage id="OrderPanel.c2c.safeDelivery" />
          </span>
        </p>
        <p className={css.auth}>
          <span>
            <FaCheck className={css.check} />
            <FormattedMessage id="OrderPanel.c2c.continuousSupport" />
          </span>
          <span className={css.moreInfoA} onClick={() => handleShowBuyerFeesModal(true, false)}>
            {intl.formatMessage({ id: 'OrderPanel.OrderPanel.moreInfo' })}
          </span>
        </p>
      </div>
    </div>
  );
  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, `square-small2x`);
  const twitterImages = listingImages(currentListing, `square-small2x`);
  const schemaImages = listingImages(
    currentListing,
    `square-small2x`
    // `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, designerLabel, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                isPublished={isPublished}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />

            <div className={css.mobileHeading}>
              {designer ? <H4 className={css.designer}>{ifOtherBrand}</H4> : null}
              <H4 as="h1" className={css.orderTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>
            {isPopnouvelleUser ? business2Customer : customer2Customer}
            <div className={css.ingressDetails}>
              <p className={css.ingressDetailsText}>
                <FormattedMessage id="ListingPage.detailsTitle" />
              </p>
              <SectionTextMaybe text={description} showAsIngress />
            </div>
            <SectionPublicDataMaybe publicData={publicData} />

            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />
            {/* <SectionFeaturesMaybe publicData={publicData} /> */}
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null || includeForListingTypes.includes(listingType);

              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value != null;
              return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value || []}
                    />,
                  ]
                : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                : pickedElements;
            }, [])}
            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            {!isPopnouvelleUser ? (
              <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            ) : null}
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              currentUser={currentUser}
              onSubmit={handleOrderSubmit}
              handleShowAuth={handleShowAuth}
              handleShowBuyerFeesModal={handleShowBuyerFeesModal}
              handleShowCondition={handleShowCondition}
              handleShowBusinessToCustomer={handleShowBusinessToCustomer}
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              payoutDetailsWarning={payoutDetailsWarning}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
            />
          </div>
        </div>
        <Modal
          {...props}
          id="ListingPage.buyerFeesModal"
          isOpen={showBuyerFeesModal}
          onClose={() => handleShowBuyerFeesModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          isTopbar={true}
        >
          <div className={css.buyerFeesModalContent}>
            {showInfo ? (
              <>
                <h3 className={css.buyerFeesModalTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.priceDetails' })}
                </h3>
                <div className={css.priceContainer}>
                  <p className={css.priceLabel}>
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.price' })}
                  </p>
                  {formatPriceWithoutBuyerFee ? (
                    <p className={css.priceValue}>{formatPriceWithoutBuyerFee?.formattedPrice}</p>
                  ) : null}
                </div>
                {formatBuyerFee ? (
                  <div className={css.priceContainer}>
                    <p className={css.priceLabel}>
                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.buyerServiceFee',
                      })}
                    </p>
                    {formatBuyerFee ? (
                      <p className={css.priceValue}>{formatBuyerFee?.formattedPrice}</p>
                    ) : null}
                  </div>
                ) : null}
                <div className={css.priceContainer}>
                  <p className={css.totalLabel}>
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.total' })}
                  </p>
                  {formattedPrice ? <p className={css.totalPrice}>{formattedPrice}</p> : null}
                </div>
                <p className={css.note}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.shippingNote' })}
                </p>
                <div className={css.divider} />
              </>
            ) : null}
            <h3 className={css.buyerFeesModalTitle}>
              {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.noteOne' })}
            </h3>
            <div className={css.section}>
              <div className={css.icon}></div>
              <div className={css.sectionContent}>
                <div className={css.sectionIcons}>
                  <img src={moneyback} alt="moneyback" className={css.popupLogo} />
                  <p className={css.sectionText}>
                    <b>{intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.noteTwo' })}</b>{' '}
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.noteThree' })}
                  </p>
                </div>
              </div>
              <div className={css.sectionContent}>
                <div className={css.sectionIcons}>
                  <img src={inspection} alt="inspection" className={css.popupLogoInspection} />
                  <p className={css.sectionText}>
                    <b>
                      {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.inspection' })}
                    </b>{' '}
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.inspectiontext' })}
                  </p>
                </div>
              </div>
            </div>
            <div className={css.section}>
              <div className={css.icon}></div>
              <div className={css.sectionContent}>
                {!isPriceGreaterOrEqual && (
                  <div className={css.sectionIcons}>
                    <img src={returnright} alt="right to return" className={css.popupLogo} />
                    <p className={css.sectionText}>
                      <b>
                        {intl.formatMessage({
                          id: 'ListingPage.ListingPageCarousel.authentication',
                        })}
                      </b>

                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.authenticationSubheading',
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={css.section}>
              <div className={css.icon}></div>
              <div className={css.sectionContent}>
                <div className={css.sectionIcons}>
                  <img src={safedelivery} alt="safe delivery" className={css.popupLogo} />
                  <p className={css.sectionText}>
                    <b>
                      {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.freeRelist' })}
                    </b>{' '}
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.authNoteOne' })}
                  </p>
                </div>
              </div>
            </div>
            <div className={css.section}>
              <div className={css.icon}></div>
              <div className={css.sectionContent}>
                <div className={css.sectionIcons}>
                  <img src={customercare} alt="customer care" className={css.popupLogo} />
                  <p className={css.sectionText}>
                    <b>
                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.customerSupport',
                      })}
                    </b>{' '}
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.chatWithEmail' })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          {...props}
          id="PopNouvelleModal"
          isOpen={isOpenBusinessToCustomer}
          onClose={() => handleShowBusinessToCustomer(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          isTopbar={true}
        >
          <div className={css.modalContent}>
            <div className={css.containerHeight}>
              <h3 className={css.buyerFeesModalTitle}>
                <FormattedMessage id="PopNouvelleModal.title" values={{ br: <br /> }} />{' '}
              </h3>
              <div className={css.sectionb2c}>
                <img src={verified} alt="authentication" className={css.popupLogo} />
                <p className={css.sectionText}>
                  <b>{intl.formatMessage({ id: 'PopNouvelleModal.authentication' })}</b>
                  {intl.formatMessage({ id: 'PopNouvelleModal.authenticationText' })}
                </p>
              </div>
              <div className={css.sectionb2c}>
                <img src={freeDelivery} alt="free delivery" className={css.popupLogo} />
                <p className={css.sectionText}>
                  <b>{intl.formatMessage({ id: 'PopNouvelleModal.freeDelivery' })}</b>
                  {intl.formatMessage({ id: 'PopNouvelleModal.freeDeliveryText' })}
                </p>
              </div>
              <div className={css.sectionb2c}>
                <img src={returnright} alt="return policy" className={css.popupLogo} />
                <p className={css.sectionText}>
                  <b>{intl.formatMessage({ id: 'PopNouvelleModal.returnPolicy' })}</b>
                  {intl.formatMessage({ id: 'PopNouvelleModal.returnPolicyText' })}
                </p>
              </div>
              <div className={css.sectionb2c}>
                <img src={customercare} alt="support" className={css.popupLogo} />
                <p className={css.sectionText}>
                  <b>{intl.formatMessage({ id: 'PopNouvelleModal.support' })}</b>
                  {intl.formatMessage({ id: 'PopNouvelleModal.supportText' })}
                </p>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          {...props}
          id="ListingPage.conditionModal"
          isOpen={showCondition}
          onClose={() => handleShowCondition(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          isTopbar={true}
        >
          <div className={css.buyerFeesModalContent}>
            <h3 className={css.buyerFeesModalTitle}>
              {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.productCondtion' })}
            </h3>
            <div className={css.section}>
              <div className={css.sectionContent}>
                <h4 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.neverWorn' })}
                </h4>
                <p className={css.sectionText}>
                  {intl.formatMessage({
                    id: 'ListingPage.ListingPageCarousel.neverWornSubheading',
                  })}
                </p>
              </div>
              <div className={css.divider} />
              <div className={css.sectionContent}>
                <h4 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.wornNever' })}
                </h4>
                <p className={css.sectionText}>
                  {intl.formatMessage({
                    id: 'ListingPage.ListingPageCarousel.wornNeverSubheading',
                  })}
                </p>
              </div>
              <div className={css.divider} />
              <div className={css.sectionContent}>
                <h4 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.vGoodCondtion' })}
                </h4>
                <p className={css.sectionText}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.vGoodConditionNote' })}
                </p>
              </div>
              <div className={css.divider} />
              <div className={css.sectionContent}>
                <h4 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.goodCondition' })}
                </h4>
                <p className={css.sectionText}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.goodConditionNote' })}
                </p>
              </div>
              <div className={css.divider} />
              <div className={css.sectionContent}>
                <h4 className={css.sectionTitle}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.fairCondition' })}
                </h4>
                <p className={css.sectionText}>
                  {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.fairConditionNote' })}
                </p>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          {...props}
          id="ListingPage.authModal"
          isOpen={showAuth}
          onClose={() => handleShowAuth(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          isTopbar={true}
        >
          <div className={css.buyerFeesModalContent}>
            <h3 className={css.buyerFeesModalTitle}>
              {intl.formatMessage({
                id: 'ListingPage.ListingPageCarousel.authentcicityAndQualityControl',
              })}
            </h3>
            <div className={css.section}>
              <p className={css.sectionText}>
                {isPopnouvelleUser ? (
                  <FormattedMessage
                    id="ListingPage.ListingPageCarousel.authenticityTxtB2C"
                    values={{ br: <br /> }}
                  />
                ) : (
                  <FormattedMessage id="ListingPage.ListingPageCarousel.authenticityTxtOne" />
                )}
              </p>
              {!isPopnouvelleUser && (
                <>
                  <p className={css.sectionTextOne}>
                    {intl.formatMessage({ id: 'ListingPage.ListingPageCarousel.textSectonVerify' })}
                  </p>
                  <div className={css.sectionText}>
                    <div className={css.sectionIcons}>
                      <img src={verified} alt="verified" className={css.popupLogo} />
                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.authenticityTextTwo',
                      })}
                    </div>
                    <div className={css.sectionIcons}>
                      <img src={chatbubble} alt="chatbubble" className={css.popupLogo} />
                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.authenticityTextThree',
                      })}
                    </div>
                    <div className={css.sectionIcons}>
                      <img
                        src={writing}
                        alt="writing"
                        className={css.popupLogo}
                        style={{ padding: '5px' }}
                      />
                      {intl.formatMessage({
                        id: 'ListingPage.ListingPageCarousel.authenticityTextFour',
                      })}
                    </div>
                  </div>
                  <p className={css.lastText}>
                    {intl.formatMessage({
                      id: 'ListingPage.ListingPageCarousel.authenticityNOteLast',
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
        </Modal>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };

    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
